/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: rgba(255, 255, 255, 0.918) !important;
  padding: 20px;
  border-radius: 8px !important;
  position: relative;
  max-width: 80%;
  height: 80%;
  overflow: auto;
  scrollbar-width: thin;
}
/* Image inside modal */
.modal-image {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}
/* Close Button */
.close-modal-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: white;
  color: black;
  border: none;
  padding: 7px 9px;
  cursor: pointer;
  border-radius: 15%;
  font-weight: 700;
}
.modal-print-btn {
  position: absolute;
  top: 5px;
  right: 50px;
}
.close-modal-btn:hover {
  background-color: #cc0000;
  color: white;
}
