@import url(./popup.css);
@import url(./modal.css);
.loginMainContainer {
  width: 100%;
  background: url("/src/common/assets/images/login_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin: 0px;
}
.loginMainBlock {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.login-image-block {
  width: 50%;
}
.login-input-container {
  width: 50%;
}
.login-image-container {
  width: 90%;
  height: 90%;
}
.passwordBlock {
  width: 20px;
  height: 20px;
}
.crm-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.login-input-main-block {
  background: #3baceb;
  width: 74%;
}
.login-logo-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
.login-logo {
  width: 25%;
  height: 25%;
}
.login-logo-heading {
  font-family: "Times New Roman", Times, serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 42.19px;
  text-align: center;
  color: #ffffff;
}
.login-input-block {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.login-label {
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #ffffff;
}
.loginInputField {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
}
.loginInputFieldBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px 0px;
}
.login-btn {
  height: 45px;
  border-radius: 20px;
  background: #ffffff;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.78px;
  text-align: center;
  color: #000000;
  border: none;
  width: 45%;
}
.login-btn:hover {
  background-color: rgba(0, 0, 255, 0.507);
  border: 1px solid white;
  color: #ffffff;
}
.loginPasswordBlock {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  height: 45px;
  padding: 0px 5px;
}
.overviewMainSection {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #4068f3;
}
.overview-main-block {
  width: 100%;
  height: 95vh;
  /* overflow-y: auto; */
  overflow: hidden;
  scrollbar-width: none;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
}
.overview-heading-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overview-search-block {
  display: flex;
  gap: 10px;
  align-items: center;
}
.overview-head {
  font-family: "Times New Roman", Times, serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 42.19px;
  text-align: left;
  color: #000000;
}
.overview-statusbar {
  height: Fixed (42px) px;
  padding: 7px 10px 7px 10px;
  border-radius: 7px;
  background: #4068f3;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}
.search-block {
  width: 50%;
  border-radius: 10px;
  background: #004bcb66;
  border: 2px solid #000000;
  height: 40px;
  padding: 0px 5px;
}
.user-search-block {
  width: 55%;
  border-radius: 10px;
  background: #004bcb66;
  border: 2px solid #000000;
  height: 40px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}
.search-icon {
  width: 25px;
  height: 25px;
}
.table-search {
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
}
.overview-table-main-block {
  width: 100%;
  height: 80vh;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  background-color: #ffffff;
  margin: 10px 0px;
}
.user-download-block {
  width: 45%;
  height: Fixed (42px) px;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 7px;
  background: #4068f3;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
}
.overview-download-block {
  width: 30%;
  height: Fixed (42px) px;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 7px;
  background: #4068f3;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
}
.overview-btn {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  color: #ffffff;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
}
.overview-table-block {
  width: 100%;
  /* height: 70vh !important; */
  margin-top: 10px;
  padding-bottom: 10px !important;
  overflow: auto;
  scrollbar-width: thin;
}

.overview-table {
  width: 100%;
  border-collapse: separate; /* This is crucial for adding space between rows */
  border-spacing: 0 3px;
}
.table-container {
  overflow: auto;
  scrollbar-width: thin;
  width: 100%;
}
.overview-table tbody tr {
  box-shadow: 4px 5px 10px 0px #436850;
}
.overview-table-head {
  font-family: "Times New Roman", Times, serif;
  background: #4068f3 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 5px 15px !important;
  min-width: 140px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4 !important;
  vertical-align: middle;
}

.overview-table-data {
  font-family: "Times New Roman", Times, serif;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-align: center !important;
  background: none !important;
  padding: 10px 15px !important;
  min-width: 140px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4 !important;
  vertical-align: middle;
}
.table > :not(caption) > * > * {
  background-color: none !important;
}

.pending-row {
  background-color: #e90000 !important;
  /* color: #ffff !important; */
}
.completed-row {
  background-color: #ffffff !important;
}
.table-heading {
  position: sticky;
  top: 0px;
}

.technicians-table-data {
  font-family: "Times New Roman", Times, serif;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-align: center !important;
  background: #ffffff !important;
  padding: 15px 15px !important;
  min-width: 140px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4 !important;
  vertical-align: middle;
}

.technicians-table-head {
  font-family: "Times New Roman", Times, serif;
  background: #4068f3 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 13px 15px !important;
  min-width: 140px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4 !important;
  vertical-align: middle;
}
.view-user-table-head {
  padding: 14px !important;
  font-family: "Times New Roman", Times, serif;
  font-size: 19px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  min-width: 160px;
  color: #ffffff !important;
  background: #4068f3 !important;
}
.view-user-table-data {
  padding: 14px !important;
  background: #ffffff !important;
  font-family: "Times New Roman", Times, serif;
  font-size: 17px;
  font-weight: 700;
  min-width: 160px;
  line-height: normal;
  text-align: center;
  color: #000000;
  border: none !important;
}
/* Loading start */
.wrapper {
  width: 200px;
  height: 20px;
  position: absolute;
  transform: translate(50%, 50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #adadad7c;
  left: 10%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 10%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  font-family: "Lato";
  font-size: 20px;
  letter-spacing: 12px;
  color: #000;
  left: 10%;
}
/* INVOICE STYLES */
.invoiceMainBlock {
  scrollbar-width: none;
  width: 595px;
  position: relative;
  /* border: 1px solid red; */
  height: 842px;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  border: 1px dotted black;
}
.invoice-head {
  font-family: "Times New Roman", Times, serif;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  border-bottom: 2px solid black;
}
.invoice-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.invoiceMainContainer {
  width: 100%;
  height: 95vh;
  overflow: auto;
  scrollbar-width: none;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  position: relative;

  /* border: 1px solid red; */
}
.invoice-text {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}
.invoice-text-format {
  font-size: 14px;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
  color: black;
}
.text-color {
  color: blueviolet;
  font-weight: 600;
  margin: 3px 0px;
}
.invoice-table-head {
  font-size: 14px;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  color: white;
  padding: 8px 0px;
  text-align: center;
  background-color: rgb(14, 121, 80);
}
.invoice-table-data {
  font-size: 12px;
  font-weight: 700;
  padding: 8px 0px;
  font-family: "Times New Roman", Times, serif;
  color: black;
  text-align: center;
}
.footer-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
  color: black;
  position: absolute;
  bottom: 0px;
  right: 9px;
  opacity: 0.6;
}
.table-head {
  font-size: 14px;
  border: 1px solid black;
  text-align: center;
  padding: 10px 0px;
  font-weight: 800;
  font-family: "Times New Roman", Times, serif;
}
.table-data {
  font-size: 12px;
  border: 1px solid black;
  text-align: center;
  padding: 10px 0px;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
}
.modal-popup {
  background-color: #ffffff !important;
  padding: 20px;
  border-radius: 8px !important;
  position: relative;
  max-width: 80%;
  width: 53%;
  overflow: auto;
  scrollbar-width: thin;
}
.css-13cymwt-control {
  background-color: transparent !important;
  border: none !important;
  /* font-size: 14px !important; */
}
.popup-payment-heading {
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  border-bottom: 1px solid black;
}
.popup-label {
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
}
.popop-input-field {
  /* text-align: start; */
  font-size: 16px;
  padding-left: 8px;
  height: 36px;
  width: 100%;
  background: #004bcb66;
  border: none;
  border-radius: 5px;
  outline: none;
  padding-right: 5px;
}
.popup-input-submit {
  background-color: #4068f3;
  border: none;
  border-radius: 10px;
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #ffff;
  width: 40%;
}
.popup-input-submit:hover {
  background-color: #1346ff;
}
.product-download{
  width: 100% ;
}