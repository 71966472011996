.popup-content-box {
  position: relative;
  width: 80%;
  max-width: 300px;
  background: #004bcb;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tech-details {
  width: 40%;
  background: #4068f3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 0px;
}
.track-map-user {
  width: 50%;
  background: #4068f3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 0px;
}
.request-details {
  width: 60%;
  background: #ffffff !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.track-details {
  width: 50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: url("./common/assets/images/trackmap.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.popup-logo-heading {
  font-family: "Times New Roman", Times, serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 35.16px;
  text-align: center;
  color: #ffffff;
}
.popup-logo-block {
  width: 20%;
  height: 20%;
}
.popup-heading-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-profile {
  width: 150px;
  height: 150px;
}
.popup-profile-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.request-user-details {
  width: 100%;
  display: flex;
}
.request-key {
  width: 50%;
  text-align: start;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
  margin: 1px 0px;
  padding-left: 8px;
}

.request-value {
  width: 50%;
  margin: 1px 0px;
  padding-left: 5px;
  font-family: "Times New Roman", Times, serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
}
.tech_key {
  /* width: 50%; */
  text-align: start;
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
  margin: 1px 0px;
  padding-left: 8px;
}
.tech_value {
  text-align: start;
  margin: 1px 0px;
  padding-left: 5px;
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
}
.popup-image {
  width: 100px;
  height: 80px;
}
.popup-btn {
  height: 40px;
  padding: 8px 0px;
  width: 45%;
  background: #4068f3;
  border: none;
  border-radius: 10px;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #ffffff;
}
.popup-btn:hover {
  background: #0028b9;
}
.close-icon-block {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.popup-phone {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background: #00ff19;
}

.popup-share {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background: #cacf00;
}
.delete-content {
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  color: #ffffff;
  margin: 5px 0px;
}
.delete-popup-block {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.delete-popup-btn {
  height: Hug (40px) px;
  padding: 7px 25px;
  border-radius: 15px;
  background: #ffffff;
  border: none;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  color: #000000;
}
.popup-delete-box {
  position: relative;
  width: 80%;
  max-width: 250px;
  background: #ccddf9;
  border-radius: 8px;
  padding: 5px;
}
.delete-sub-popup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.delete-sub-popup-btn {
  height: Hug (40px) px;
  padding: 7px 35px 7px 35px;
  border-radius: 20px;
  background: #004bcb;
  border: none;
  outline: none;
  font-family: "Times New Roman", Times, serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.78px;
  text-align: left;
  color: #ffffff;
}
.delete-sub-popup-content {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.78px;
  text-align: center;
  color: #004bcb;
}
.timeline-box {
  width: 100%;
  height: auto;
  padding: 15px 15px 10px 15px;
  margin-left: 10px;
  /* display: flex;
  justify-content: space-evenly; */
}
.timeline-left {
  padding: 0px 8px;
  width: 100%;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #ffffff;
}
.timeline-right {
  padding: 10px;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* .tech-icon-block {
  position: absolute;
} */
.popup-content {
  /*
  padding: 10px;
  width: 80%;
  max-width: 500px; */
  border-radius: 10px;
  position: relative;
  width: 80%;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}
.popup-box {
  width: 100%;
  display: flex;
  background: #4068f3;
}

.tech-user-details {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  padding: 0px 3px;
  margin: 2px;
}
.popup-delete {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background: #f80000;
}
.popup-edit {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background: #4068f3;
}
.popup-call-btn {
  /* width: Hug (170px) px; */
  height: Hug (40px) px;
  padding: 7px 35px 7px 35px;
  border-radius: 20px;
  background: #ffffff;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0px;
  line-height: 25.78px;
  text-align: center;
  color: #000000;
  border: none;
}
.popup-calltech-btn {
  height: Hug (40px) px;
  padding: 3px 30px 3px 30px;
  border-radius: 20px;
  background: #ffffff;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 700;
  margin: 5px 0px;
  line-height: normal;
  text-align: center;
  color: #000000;
  border: none;
}
.assign-tech {
  font-family: "Times New Roman", Times, serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;
  color: #004bcb;
}
.--product-delete-popup{
  width: 100% !important;
}